import React, { useState, Fragment } from "react"
import { graphql, Link } from "gatsby"

import { PlusSmIcon } from '@heroicons/react/solid'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import TopicList from "../components/topic/topiclist"
import Categories from "../components/topic/categories"

import { convertToSlug } from "../utils/text";
import { classNames } from '../utils/tailwind'


const TopicsPage = ({data}) => {
  const [countRecentToShow, setCountRecentToShow] = useState(4);  // default topics to show, until user expands
  const isMobile = (typeof window !== 'undefined') && window.screen.width < 600;
  const sortOptions = ['Communities', 'Popularity', 'Alphabetical'];
  const [sort, setSort] = useState(sortOptions[0]);

  var allTopics = data.allTopics.nodes;
  if (sort === 'Popularity'){
    allTopics = allTopics.sort((a,b) => (a.visitors < b.visitors) ? 1 : ((b.visitors < a.visitors) ? -1 : 0))
  } else if (sort === 'Alphabetical'){
    allTopics = allTopics.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  } else if (sort === 'Communities') {
    allTopics = allTopics.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
  }

  // assemble the categories from list of all topics
  var categories = [];
  allTopics.filter(t => t.category).forEach(t => {
    const index = categories.map(c => c.name).indexOf(t.category);
    if (index === -1){
      categories.push({'slug': convertToSlug(t.category), 'name': t.category, 'count': 1})
    } else {
      categories[index].count += 1;
    }
  });
  const allCategories = [
    {'slug': 'all', 'name': 'All', 'count': allTopics.length},
    ...categories.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)),
    // {'slug': 'uncategorized', 'name': 'Uncategorized', 'count': data.allTopics.nodes.filter(t => !t.category).length},
  ]

  return (
    <Layout pageTitle="All Online Community Topics" enableShare={true} >
      <SEO title="Topics | Online Community Topics" description="Discover the best online communities, for any topic you are interested in. " 
        generateImage={true} path={'/topics/'}
      />
        
      {/*<div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Recently Added</h2>
        </div>
      </div>

      <div className="mt-2">
        <TopicList topics={data.recentTopics.nodes.filter((t, i) => i < countRecentToShow)} mini={isMobile} fullWidthMini={true} />
        {countRecentToShow < data.recentTopics.nodes.length ? (
          <div className="pt-4 mx-auto text-center">
            <div className="mx-auto text-gray-600 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
              onClick={() => setCountRecentToShow(data.recentTopics.nodes.length)}
            >
              Show More →
            </div>
          </div>
        ) : ''}
      </div>*/}

      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">All Community Topics</h2>
          <span className="flex-shrink-0 font-semibold text-xs text-gray-500 bg-white px-1 pr-2">
            {data.allTopics.totalCount}
          </span>
          {/*<Link to="/suggest/" className="ml-auto">
            <button
              type="button"
              className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
            >
              <PlusSmIcon className="-ml-1.5 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              <span>New</span>
            </button>
          </Link>*/}

          <div className="ml-auto bg-white pl-4">
            {sortOptions && sortOptions.length ? (
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        // sort ? 'border-rose-500 bg-rose-50' : '',
                        'group flex mt-1 block p-2 sm:px-3 sm:py-2 w-full text-xs border-gray-300 focus:outline-none focus:ring-rose-500 border border-gray-200 focus:border-rose-500 sm:text-sm rounded-md'
                      )}
                    >
                      <span>{sort}</span>
                      <ChevronDownIcon
                        className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'hidden sm:block ml-auto h-5 w-5 group-hover:text-gray-500')}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 right-0 mt-3 px-2 w-36 sm:px-0">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-1 bg-white p-2">
                            {sortOptions.map((t) => (
                              <div
                                key={t}
                                onClick={() => setSort(t)}
                                className={`p-1 flex items-start cursor-pointer rounded-lg hover:bg-gray-50 transition ease-in-out duration-150 ${t === sort ? 'bg-rose-50' : ''}`}
                              >
                                {/*<item.icon className="flex-shrink-0 h-6 w-6 text-rose-600" aria-hidden="true" />*/}
                                <div className="ml-0">
                                  <div className="text-base font-medium text-gray-900 flex">
                                    <div>{t}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            ) : ''}
          </div>
        </div>
      </div>
      <div className="mt-2 grid grid-cols-3 sm:grid-cols-4 sm:gap-x-6 xl:gap-x-8">
        <div className="hidden sm:block mt-2">
          <Categories categories={allCategories} />
        </div>

        <div className="col-span-3">
          <TopicList topics={data.allTopics.nodes} mini={true} cols={3} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
  recentTopics: allGoogleSpreadsheetSourceTopics(sort: {fields: order, order: DESC}, limit: 12, filter: {showRecent: {eq: "y"}, count: {gt: 0}}) {
    totalCount
    nodes {
      slug
      isRoot
      name
      id
      count
      image
      people
      category
      visitors
      views
    }
  }
  allTopics: allGoogleSpreadsheetSourceTopics(sort: {fields: count, order: DESC}) {
    totalCount
    nodes {
      slug
      isRoot
      name
      id
      count
      image
      people
      category
      visitors
      views
    }
  }
}
`

export default TopicsPage
