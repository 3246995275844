import React from "react"
import { Link } from "gatsby"
import withLocation from "../common/withlocation"

// lists a bunch of topics
const Categories = ({categories, location}) => {
  
  const isCurrent = (slug) => {
    if (slug === 'all') return location.pathname === `/topics/`;
    return location.pathname === `/category/${slug}/`;
  }

  return (
    <div className={`space-y-1 -mx-3`}>
      {categories.map(c=>(
        <Link key={c.slug} to={c.slug === 'all' ? '/topics/' : `/category/${c.slug}/`}
          className={`p-2 px-3 rounded-md flex items-center cursor-pointer ${isCurrent(c.slug) ? 'bg-rose-100' : 'hover:bg-rose-50'}`}
        >
          <div className="font-semibold">{c.name}</div>
          <div className="ml-auto font-bold opacity-25 text-sm">{c.count}</div>
        </Link>
      ))}
    </div>
  )
}

export default withLocation(Categories);

